module.exports = {
  methods: {
    /**
     * @params multigroupLabel array of objects
     * This function takes the multigroupLabel and creates the breadcrumbArray with Classes or Groups depending of org type
     * jcervantes 7-30-2018
     */
    getBreadcrumbArray: function(multigroupLabel) {
      // get plural Org label and capitalize the first letter jcervantes 07-30-2018
      const plural = multigroupLabel.plural.charAt(0).toUpperCase() + multigroupLabel.plural.slice(1);
      const singular = multigroupLabel.singular;
      return [
        { label: plural, route: { name: 'classes' } },
        { label: 'Get started', route: { name: 'CreateLessonType' } },
        { label: 'Name ' + singular, route: { name: 'LessonPlanName' } },
        { label: 'Select topic', route: { name: 'subject' } },
        { label: 'Select lesson plan', route: { name: 'plan' } },
        { label: 'Preview', route: { name: 'preview' } }
      ];
    }
  }
};
