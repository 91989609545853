<template>
  <div v-if="adminLoaded">
    <nav class="navbar fixed-top breadcrumbs-nav bg-white">
      <Breadcrumbs :breadcrumb-prop="breadcrumb" />
    </nav>
    <router-view />
  </div>
</template>

<script>
import BaseComponent from '@/components/BaseComponent';
import breadcrumbMixin from '@/utils/breadcrumbs.js';
import Breadcrumbs from '@/components/Breadcrumbs';

export default {
  name: 'LessonPlanCreateBase',
  components: { Breadcrumbs },
  extends: BaseComponent,
  mixins: [breadcrumbMixin],
  data() {
    return {
      adminLoaded: false,
      breadcrumbArray: []
    };
  },
  beforeRouteEnter(to, from, next) {
    // Wait for user to resolve to get settings jcervantes 07-23-2018
    next(vm => {
      vm.$store.dispatch('header/loadHeaderData').then(
        () => {
          // make sure only admins and master_admin get to this components
          if (vm.$store.getters['user/isOrgAdmin']) {
            vm.adminLoaded = true;
            // call mixing to retrieve breadcrumbArray, this receives the multrigroupLabel and builds the array with group/class depending on org jcervantes 07-30-2018
            vm.breadcrumbArray = vm.getBreadcrumbArray(
              vm.$store.getters['header/getMultigroupLabel']
            );
            // removed from created() and added it here because it needs to be called after the header store is populated
            vm.breadcrumb = vm.crumbler();
          } else {
            // if not logged in or not an admin kick him back to login page 07-25-2018
            vm.redirectUrl(process.env.SPENV.APP_URL + '/lmsapp/dashboard');
          }
        },
        error => {
          console.log(error);
        }
      );
    });
  },
  watch: {
    $route: function(val, oldVal) {
      this.breadcrumb = this.crumbler();
    }
  },
  methods: {
    crumbler() {
      for (let i = 0; i < this.breadcrumbArray.length; i++) {
        if (this.breadcrumbArray[i].route.name === this.$route.name) {
          return this.breadcrumbArray.slice(0, i + 1);
        }
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
